<template>
  <AdminPage>
    <Notification
      v-if="success"
      type="success"
    >
      {{ success }}
    </Notification>
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>

    <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
        </div>
      </div>
      <!-- Right side -->
      <div class="level-right">
        <div class="level-item">
          <button
            class="button"
            @click="toggleMarketImport"
          >
            Import Market
          </button>
        </div>
        <p class="level-item"><strong>{{ marketsCount }}</strong></p>
      </div>
    </nav>

    <form
      v-if="showMarketImport"
      @submit.prevent="importMarket"
    >
      <BaseModal
        title="Import Market"
        @close="toggleMarketImport"
      >
        <BaseSelect
          label="Discovery Map Market"
          :options="dmiMarketSelectOptions"
          v-model="selectedDmiMarket"
        />
        <template v-slot:footer>
          <button
            class="button"
            @click="toggleMarketImport"
          >
            Cancel
          </button>
          <BaseSubmit
            label="Import"
            :disabled="!canImportMarket()"
            :submitting="submittingMarketImport"
            :clearShow="false"
          />
        </template>
      </BaseModal>
    </form>

    <b-table
      :data="markets"
      :loading="loading"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      default-sort="id"
      default-sort-direction="asc"
    >
      <b-table-column
        field="id"
        label="ID"
        sortable
        v-slot="props"
      >
        {{ props.row.id }}
      </b-table-column>

      <b-table-column
        field="dmi_id"
        label="DMI ID"
        sortable
        v-slot="props"
      >
        {{ props.row.dmi_id }}
      </b-table-column>

      <b-table-column
        field="name"
        label="Name"
        sortable
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        field="slug"
        label="Slug"
        sortable
        v-slot="props"
      >
        {{ props.row.slug }}
      </b-table-column>

      <b-table-column
        field="active"
        label="Active"
        sortable
        v-slot="props"
      >
        {{ (props.row.active) ? "Yes" : "No" }}
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No markets</div>
      </template>
    </b-table>
  </AdminPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors"
import AdminPage from "@/components/AdminPage.vue";
import Notification from "@/components/Notification.vue";
import BaseModal from "@/components/modal/BaseModal.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";
import BaseSelect from "@/components/form/BaseSelect.vue";
//import api from "@/service/api.js";
import dmiApi from "@/service/dmiApi.js";

export default {
  components: {
    AdminPage,
    Notification,
    BaseModal,
    BaseSubmit,
    BaseSelect
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;
    },
    dmiMarketSelectOptions: function() {
      return this.allDmiMarkets.map((m) => {
        return {
          label: m.attributes.name,
          value: m.id
        }
      })
    },
    marketsCount: function() {
      return this.markets.length
    }
  },
  data() {
    return {
      success: null,
      error: null,
      loading: false,
      showMarketImport: false,
      submittingMarketImport: false,
      selectedDmiMarket: null,
      allDmiMarkets: [],
      markets: []
    }
  },
  mounted() {
    this.updateMarkets()

    dmiApi.getDestinations()
      .then(resp => {
        this.allDmiMarkets = resp.data.data
        this.selectedDmiMarket = 0
      })
      .catch(error => {
        if (error.response) {
          console.log(error.reponse.data.error)
          this.error = error.data.error
        } else if (error.request) {
          console.error(error.request)
          this.error = "Error loading DiscoveryMap.com destinations/markets"
        } else {
          console.error(error)
          this.error = error
        }
      })
  },
  methods: {
    updateMarkets() {
      this.loading = true

      this.$store.dispatch("getMarkets")
        .then(resp => {
          this.markets = resp.data
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.error(error)
            this.error = error
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    toggleMarketImport() {
      this.showMarketImport = !this.showMarketImport
    },
    canImportMarket() {
      return (!this.markets.find(m => m.dmi_id == this.selectedDmiMarket))
    },
    marketUrlToSlug(url) {
      let matches
      if (url != null && (matches = url.match(/^https?:\/\/(?:\w+\.)?discoverymap\.com\/([a-z0-9-]+)\/?$/i))) {
        return matches[1]
      } else {
        return ''
      }
    },
    importMarket() {
      this.submittingMarketImport = true

      const market = this.allDmiMarkets.find(m => m.id == this.selectedDmiMarket)
      const params = {
        user_slug: this.currentUser.slug,
        dmi_market: {
          dmi_id: market.id,
          name: market.attributes.name,
          slug: this.marketUrlToSlug(market.attributes.url),
          active: true
        }
      }
      this.$store.dispatch("createMarket", params)
        .then(resp => {
          this.success = `Successfully imported Discovery Map market '${resp.data.name}'!`

          this.updateMarkets()
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.error(error)
            this.error = error
          }
        })
        .finally(() => {
          this.submittingMarketImport = false
          this.toggleMarketImport()
        })
    }
  }
}
</script>
